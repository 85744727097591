import Img from "gatsby-image";
import React from "react";
import theme from "../styles/theme";

const Highlight = ({ data }) => {
  const { red, green, blue, alpha } = data.backgroundLayer;
  return (
    <section
      css={`
        margin-top: 50px;
        padding-left: 0;
        padding-right: 0;
        position: relative;
        margin-bottom: 120px;

        @media (min-width: 1600px) {
          margin-left: calc(-100vw / 2 + 1600px / 2);
          margin-right: calc(-100vw / 2 + 1600px / 2);
        }
        ${theme.tablet} {
          margin-bottom: 280px;
        }
        ${theme.max400} {
          margin-bottom: 250px;
        }
        .imgBackground {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          padding-top: 40px;
          padding-bottom: 40px;
          max-height: 700px;
          overflow: hidden;
          ${theme.max641} {
            height: 520px;
          }
          ${theme.tablet} {
            padding-top: 100px;
            padding-bottom: 100px;
          }
        }

        .layer {
          position: absolute;
          top: 0;
          left: 0;
          background: rgba(${red}, ${green}, ${blue}, ${alpha / 255});
          width: 100%;
          height: 100%;
          mix-blend-mode: multiply;
        }
        .container {
          display: flex;
          position: absolute;
          flex-wrap: wrap;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .imgLogo {
            width: 200px;
          }
        }
        .innerLeft {
          flex: 1;
          display: flex;
          flex-direction: column;
          color: #fff;
          justify-content: center;
          padding-left: 100px;
          min-width: 300px;
          ${theme.max900} {
            padding-left: 40px;
          }
          ${theme.max641} {
            padding-top: 40px;
            padding-left: 20px;
            padding-right: 20px;
          }
          .content {
            margin-top: 20px;
            max-width: 620px;
          }
          p {
            font-size: 22px;
            font-weight: 600;
            line-height: 130%;
            padding-bottom: 40px;
            ${theme.laptop} {
              font-size: 20px;
            }
            ${theme.tablet} {
              font-size: 16px;
            }
          }
          @media (min-width: 1600px) {
            padding-left: calc(100vw / 2 - 1380px / 2) !important;
          }
        }

        .innerRight {
          flex: 1;
          position: relative;
          min-width: 400px;
          .imgReference {
            width: 100%;
            position: absolute !important;
            bottom: -180px;
            ${theme.tablet} {
              margin-left: auto;
              margin-right: auto;
              max-width: 440px;
            }
          }
          ${theme.max641} {
            .imgReference {
              position: relative !important;
              bottom: 0px;
            }
          }
          ${theme.max400} {
            min-width: 300px;
          }
        }
      `}
    >
      <Img
        className="imgBackground"
        fluid={data.backgroundImage?.fluid}
        alt={data.backgroundImage?.alt}
        objectFit="cover"
      />
      <div className="layer" />
      <div className="container">
        <div className="innerLeft">
          <Img
            className="imgLogo"
            fluid={data.referenceLogo?.fluid}
            alt={data.referenceLogo?.alt}
          />
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: data.reference }}
          />
        </div>
        <div className="innerRight">
          <Img
            className="imgReference"
            fluid={data.referenceImage?.fluid}
            alt={data.referenceImage?.alt}
          />
        </div>
      </div>
    </section>
  );
};

export default Highlight;
