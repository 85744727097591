import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import * as React from "react";
import { StructuredText } from "react-datocms";
import Clients from "../components/Clients";
import Features from "../components/Features";
import Highlight from "../components/Highlight";
import Layout from "../components/layout";
import theme from "../styles/theme";

const ClientPage = ({ data, pageContext }) => {
  const features = data.industries.edges.find(
    item => item.node.title === data.client.industry
  );

  let featuresData;

  if (typeof features !== "undefined") {
    featuresData = {
      features: features.node.features,
      featuresItems: features.node.featuresItems,
    };
  }

  const highlightData = {
    referenceLogo: data.client.referenceLogo,
    reference: data.client.reference,
    referenceImage: data.client.referenceImage,
    backgroundImage: data.client.backgroundImage,
    backgroundLayer: data.client.backgroundLayer,
  };

  const { red, green, blue, alpha } = data.client.layer;

  return (
    <Layout
      headerType="transparent"
      location="/references"
      seo={data.client.seoMetaTags}
      logo={data.global.logoWhite}
    >
      <main
        css={`
          margin: 0 auto;
          padding-bottom: 200px;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          ${theme.tablet} {
            padding-bottom: 0;
          }
        `}
      >
        <section
          css={`
            @media (min-width: 1600px) {
              margin-left: calc(-100vw / 2 + 1600px / 2);
              margin-right: calc(-100vw / 2 + 1600px / 2);
              .content {
                padding-left: calc(100vw / 2 - 1380px / 2) !important;
              }
            }
            position: relative;
            padding-left: 0;
            padding-right: 0;
            height: 700px;
            svg {
              cursor: pointer;
            }
            .heroImg {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              img {
                overflow: hidden;
                object-fit: cover;
              }
            }
            .layer {
              position: absolute;
              top: 0;
              left: 0;
              background: rgba(${red}, ${green}, ${blue}, ${alpha / 255});
              width: 100%;
              height: 100%;
              mix-blend-mode: multiply;
            }
            .customersLogo {
              width: 100%;
              height: auto;
              max-width: 220px;
            }
            .content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 100%;
              padding-left: 120px;
              max-width: 630px;
              position: absolute;
              top: 0;
              left: 0;
              color: #fff;
              h1 {
                margin-top: 100px;
                font-size: 72px;
                margin-bottom: 0;
                ${theme.laptop} {
                  font-size: 58px;
                  margin-top: 80px;
                }
                ${theme.tablet} {
                  margin-top: 10px;
                }
                ${theme.mobile} {
                  font-size: 36px;
                  margin-top: 10px;
                }
              }
              p {
                font-size: 24px;
                line-height: 120%;
                margin-bottom: 24px;
              }
            }
            ${theme.laptop} {
              .content {
                padding-left: 80px;
              }
            }
            ${theme.tablet} {
              .content {
                padding-left: 40px;
                padding-right: 20px;
                h1 {
                  font-size: 68px;
                }
                p {
                  font-size: 18px;
                }
              }
            }
            ${theme.mobile} {
              height: 600px;
              .content {
                padding-left: 20px;
                h1 {
                  font-size: 32px;
                }
              }
            }
          `}
        >
          <Img
            className="heroImg"
            fluid={data.client.heroImg?.fluid}
            alt={data.client.heroImg?.alt}
          />
          <div className="layer" />

          <div className="content">
            {data.client.clientLogo ? (
              <Img
                className="customersLogo"
                fluid={data.client.clientLogo?.fluid}
                alt={data.client.clientLogo?.alt}
              />
            ) : (
              <h1>{data.client.title}</h1>
            )}

            <p>{data.client.text}</p>
            <svg
              onClick={() => scrollTo("#mainStart")}
              width="63"
              height="63"
              viewBox="0 0 63 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d)">
                <circle
                  cx="31.5"
                  cy="29.5"
                  r="27.0417"
                  transform="rotate(90 31.5 29.5)"
                  stroke="white"
                  stroke-width="4.91667"
                />
                <path
                  d="M44.9727 21.1236L33.3308 40.4802C32.3754 42.0686 30.0728 42.0686 29.1175 40.4802L17.4755 21.1236"
                  stroke="white"
                  stroke-width="4.91667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d"
                  x="0.361111"
                  y="0"
                  width="62.2778"
                  height="62.2778"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dy="1.63889" />
                  <feGaussianBlur stdDeviation="0.819444" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </section>
        <section
          id="mainStart"
          css={`
            margin-top: 80px;
            margin-bottom: 140px;
            flex-direction: column;
            ${theme.mobile} {
              margin-top: 40px;
              margin-bottom: 80px;
            }
            div.first {
              display: flex;
              flex-wrap: wrap;
            }
            div.second {
              margin-top: 60px;
              ${theme.mobile} {
                margin-top: 0;
              }
              display: flex;
              flex-wrap: wrap-reverse;
            }
            > div > div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex: 1;
              min-width: 280px;
            }
            .textBox {
              box-sizing: border-box;
              a {
                color: ${theme.pink};
                font-weight: 600;
                opacity: 0.7;
                &:hover {
                  opacity: 1;
                }
              }
              h3 {
                font-size: 28px;
                color: ${theme.blue};
                margin: 30px 0 0;
                font-weight: 900;
              }
              p {
                font-size: 18px;
                line-height: 130%;
              }
              .button {
                margin: 20px 0 5px;
              }
            }
            .imgBox {
              box-sizing: border-box;

              .img {
                width: 100%;
              }
            }
            ${theme.laptop} {
              .textBox p {
                font-size: 18px;
              }
            }
            .first .textBox {
              padding-right: 40px;
            }
            .first .imgBox {
              padding-left: 40px;
            }
            .second .textBox {
              padding-left: 40px;
            }
            .second .imgBox {
              padding-right: 40px;
            }
            ${theme.mobile} {
              .first .textBox {
                padding-right: 0;
              }
              .first .imgBox {
                padding-left: 0;
                padding-top: 20px;
                padding-bottom: 20px;
              }
              .second .textBox {
                padding-left: 0;
              }
              .second .imgBox {
                padding-right: 0;
                padding-top: 20px;
                padding-bottom: 20px;
              }
            }
          `}
        >
          <div className="first">
            <div className="textBox">
              <StructuredText
                data={data.client.lead}
                renderBlock={({ record }) => {
                  switch (record.__typename) {
                    case "DatoCmsButton":
                      return (
                        <div>
                          <Link className="button" to={record.url}>
                            {record.text}
                          </Link>
                        </div>
                      );
                    default:
                      return null;
                  }
                }}
              />
            </div>
            <div className="imgBox">
              <Img
                className="img"
                fluid={data.client.leadImage?.fluid}
                alt={data.client.leadImage?.alt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
          </div>
          <div className="second">
            <div className="imgBox">
              <Img
                className="img"
                fluid={data.client.leadImage2?.fluid}
                alt={data.client.leadImage2?.alt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
            <div className="textBox">
              <StructuredText
                data={data.client.lead2}
                renderBlock={({ record }) => {
                  switch (record.__typename) {
                    case "DatoCmsButton":
                      return (
                        <div>
                          <Link className="button" to={record.url}>
                            {record.text}
                          </Link>
                        </div>
                      );
                    default:
                      return null;
                  }
                }}
              />
            </div>
          </div>
        </section>

        <Highlight data={highlightData} />

        {featuresData && <Features data={featuresData} mt="220px" />}

        <Clients dataClients={data.clients} />
      </main>
    </Layout>
  );
};

export default ClientPage;

export const query = graphql`
  query ClientQuery($slug: String!) {
    client: datoCmsClient(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      industry
      clientLogo {
        fluid(maxWidth: 620) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      text
      heroImg {
        fluid(maxWidth: 1400) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      layer {
        red
        green
        blue
        alpha
      }
      lead {
        value
        blocks {
          ... on DatoCmsButton {
            __typename
            id: originalId
            text
            url
            layout
          }
        }
      }
      leadImage {
        fluid(maxWidth: 580) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      lead2 {
        value
        blocks {
          ... on DatoCmsButton {
            __typename
            id: originalId
            text
            url
            layout
          }
        }
      }
      leadImage2 {
        fluid(maxWidth: 580) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      referenceLogo {
        fluid(maxWidth: 500) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      reference
      referenceImage {
        fluid(maxWidth: 500) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      backgroundImage {
        fluid(maxWidth: 1400) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      backgroundLayer {
        red
        green
        blue
        alpha
      }
    }
    clients: allDatoCmsClient(sort: { fields: [position], order: ASC }) {
      edges {
        node {
          title
          slug
          text
          thumbnail {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    }
    industries: allDatoCmsIndustry {
      edges {
        node {
          title
          slug
          features {
            value
          }
          featuresItems {
            title
            content
            icon {
              url
              alt
            }
          }
        }
      }
    }
    global: datoCmsGlobal {
      logoWhite {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
    }
  }
`;
